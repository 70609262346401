<template>
  <div id="app">
    <Spreadshop />
  </div>
</template>

<script>
import Spreadshop from './components/SpreadshopComponent.vue'

export default {
  name: 'App',
  components: {
    Spreadshop
  }
}
</script>
